import { createGlobalStyle } from "styled-components";
import { fluidRange } from "polished";
import vars from "../../config/vars";

export const Reset = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }

  html, body, #root {
    width: 100%;
    height: 100%;
    pointer-events: none;
    //position: fixed;
  }

  html {
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: `${vars.rootSize * 0.7}px`,
        toSize: `${vars.rootSize}px`,
      },
      `${vars.responsiveMin}px`,
      `${vars.responsiveMax}px`
    )};
    font-family: ${vars.fontBody};
    line-height: 1em;
    color: ${vars.colors.white};
  }

  #root {
    position: relative;
    z-index: 1;
  }

  img, video {
    max-width: 100%;
    align-self: center;
    flex-shrink: 0;
  }

  p {
    font-size: ${21 / vars.rootSize}em;
    line-height: ${26 / vars.rootSize}rem;
  }

  a {
    text-decoration: none;
  }

  strong {
    font-weight: bold;
  }

  input {
    border: 1px solid ${vars.colors.white};
    background-color: transparent;
    font-size: ${17 / vars.rootSize}em;
    padding: 0.8em;
    outline: none;
    width: ${300 / vars.rootSize}rem;

    &::placeholder {
      color: inherit;
    }

    &[type="checkbox"] {
      position: relative;
      width: 17px;
      height: 17px;
      display: block;

      &:after {
        content: "";
        position: absolute;
        pointer-events: none;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background-color: ${vars.colors.white};
      }

      &:checked {
        &:after {
          background-image: url("/assets/images/tick.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50%;
        }
      }
    }
  }
`;

export const Fonts = createGlobalStyle`
  @font-face {
    font-family: "BNModernOmbra";
    src: url("/assets/fonts/BNModernOmbra.otf") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Tusker Grotesk";
    src: url("/assets/fonts/TuskerGrotesk-3800Super.woff2") format("woff2"),
    url("/assets/fonts/TuskerGrotesk-3800Super.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Tusker Grotesk";
    src: url("/assets/fonts/TuskerGrotesk-3600Semibold.woff2") format("woff2"),
    url("/assets/fonts/TuskerGrotesk-3600Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Realtime Text Rounded";
    src: url("/assets/fonts/RealtimeTextRounded-Semibold.woff2") format("woff2"),
    url("/assets/fonts/RealtimeTextRounded-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Realtime Text Rounded";
    src: url("/assets/fonts/RealtimeTextRounded-Bold.woff2") format("woff2"),
    url("/assets/fonts/RealtimeTextRounded-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Realtime Text Rounded";
    src: url("/assets/fonts/RealtimeTextRounded-Black.woff2") format("woff2"),
    url("/assets/fonts/RealtimeTextRounded-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Realtime Text Rounded";
    src: url("/assets/fonts/RealtimeTextRounded-Regular.woff2") format("woff2"),
    url("/assets/fonts/RealtimeTextRounded-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Larsseit";
    src: url("/assets/fonts/Larsseit-Bold.woff2") format("woff2"),
    url("/assets/fonts/Larsseit-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Larsseit";
    src: url("/assets/fonts/Larsseit.woff2") format("woff2"),
    url("/assets/fonts/Larsseit.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Larsseit";
    src: url("/assets/fonts/Larsseit-Light.woff2") format("woff2"),
    url("/assets/fonts/Larsseit-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
`;
