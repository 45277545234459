import React from "react";
import { Switch, Route } from "react-router-dom";
import { Reset, Fonts } from "./styles";

const Home = React.lazy(() => import("../../pages/home/home"));
const Register = React.lazy(() => import("../../pages/register/register"));
const Thanks = React.lazy(() => import("../../pages/thanks/thanks"));
const Report = React.lazy(() => import("../../pages/report/report"));
const Login = React.lazy(() => import("../../pages/login/login"));
const Landing = React.lazy(() => import("../../pages/landing/landing"));

export default function App() {
  return (
    <>
      <Reset />
      <Fonts />
      <React.Suspense fallback="Loading...">
        <Switch>
          <Route exact path="/" component={Landing} />
          {/*<Route exact path="/register" component={Register} />*/}
          {/*<Route exact path="/thanks" component={Thanks} />*/}
          <Route exact path="/report" component={Report} />
          {/*<Route exact path="/login" component={Login} />*/}
          {/*<Route exact path="/home" component={Landing} />*/}
        </Switch>
      </React.Suspense>
    </>
  );
}
