// eslint-disable-next-line import/no-anonymous-default-export
export default {
  rootSize: 20,
  responsiveMin: 320,
  responsiveMax: 1200,
  transitionTime: 350,
  fontPrimary: `Arial, sans-serif`,
  fontSecondary: `"Tusker Grotesk", sans-serif`,
  fontTertiary: `"Realtime Text Rounded", sans-serif`,
  fontBody: `"Larsseit", sans-serif`,
  colors: {
    white: "#ffffff",
    black: "#000000",
    grey: "#707070",
    grey100: "#F7F7F7",
    error: "#e4c5c5",
    primary: "#003866",
    purple900: "#2C2A66",
    purple500: "#7668AC",
    purple400: "#766AAD",
    purple350: "#7569AD",
    purple300: "#CBAFD4",
    purple200: "#C9AED3",
    purple100: "#DEDCEF",
    green400: "#2EB5B1",
    red700: "#E93F65",
    red500: "#E64067",
    red400: "#E93F64",
    orange600: "#F2AC2B",
    yellow800: "#DEDC00",
    yellow600: "#DADB01",
    green200: "#34B6B0",
    green300: "#2EB4B2",
    blue900: "#1A1A70",
    blue550: "#4A5CA4",
    blue800: "#305EAA",
    blue700: "#5987FA",
    blue650: "#4B57A2",
    blue600: "#4C58A4",
    blue500: "#8398CB",
    blue200: "#96B7CF",
    blue100: "#ACC3FC",
    blue50: "#D6E0F2",
  },
  zIndex: {
    cookieBanner: 1,
  },
  pagePadding: 1,
  cookieBannerHeight: 80,
};
